import getAvailableSelectRange from './get-available-selected-range';
import getSelectedRangeAfterRestrictions from './get-selected-range-after-restrictions';

const getNextAvailabileDates = (
  startDate,
  endDate,
  calendarAvailability,
  moment
) => {
  // break ref from redux calendar availability
  const unreffedCalendarAvailability = [...calendarAvailability];
  const nextAvailableDate = unreffedCalendarAvailability.find(
    (day) => day.isCheckIn
  );

  if (!nextAvailableDate) return { startDate, endDate };

  const selectRange = getAvailableSelectRange(
    nextAvailableDate.date,
    unreffedCalendarAvailability,
    moment
  );

  const [availabileSelectionRangeWithRestrictions] =
    getSelectedRangeAfterRestrictions(
      selectRange,
      unreffedCalendarAvailability,
      moment
    );

  if (availabileSelectionRangeWithRestrictions.length > 1) {
    return {
      startDate: availabileSelectionRangeWithRestrictions[0],
      endDate: availabileSelectionRangeWithRestrictions[1],
    };
  } else {
    return { startDate, endDate };
  }
};

export default getNextAvailabileDates;
