import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_APP_BBE_CONFIG, env } from '../../../../globals';
import { gtmAddPaymentInfo } from '../../../../gtm/events';
import useAxios from '../../../../hooks/useAxios/useAxios';
import useMessage from '../../../../hooks/useMessage/useMessage';
import { useScreenDetector } from '../../../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import { createSession } from '../../../../redux/slices/paymentSlice/paymentSlice';
import './Payment.css';

const adyenPaymentFormType = 'dropin';

const Payment = forwardRef(
  (
    {
      onSessionCreated,
      onSuccess,
      onFailure,
      metadata,
      on3dsAuth,
      onSubmit3dsCode,
      onCreateSessionFailure,
      onMountingStart,
      onMountingEnd,
      productCode,
      countryCode,
      payNowData,
      paymentOption,
      openFirstPaymentMethod = false,
      forceShowingPaymentMethodCheckBox = false,
    },
    ref
  ) => {
    const {
      t,
      constants: { errorMessage },
    } = useTranslate();
    const axios = useAxios();
    const dispatch = useDispatch();
    const { isMobile } = useScreenDetector();
    const [Message, showMessage, closeMessage] = useMessage();
    const paymentContainer = useRef(null);
    const checkout = useRef();
    const paymentBrand = useRef(null);

    const payment = useSelector((state) => state.payment);
    const bookings = useSelector((state) => state.bookings.list);
    const accessToken = useSelector((state) => state.user.accessToken);
    const shopperEmail = useSelector((state) => state.user.profile?.email);

    useImperativeHandle(ref, () => ({
      submit() {
        closeMessage();
        checkout.current?.components[0].submit();
        return checkout.current?.components[0].isValid;
      },
      reset() {
        initCreateSession();
      },
      open() {
        checkout.current?.setOptions({ openFirstPaymentMethod: true });
      },
      close() {
        checkout.current?.update({ openFirstPaymentMethod: false });
      },
    }));

    const initCreateSession = useCallback(async () => {
      onMountingStart();
      try {
        await dispatch(
          createSession(
            adyenPaymentFormType,
            accessToken ? shopperEmail : null,
            metadata,
            productCode,
            accessToken ? accessToken : null,
            countryCode,
            payNowData
          )
        );
        onSessionCreated();
      } catch (e) {
        onCreateSessionFailure(e);
        onMountingEnd();
      }
    }, [
      accessToken,
      dispatch,
      metadata,
      onSessionCreated,
      productCode,
      shopperEmail,
      countryCode,
      payNowData,
      onCreateSessionFailure,
      onMountingStart,
      onMountingEnd,
    ]);

    const showErrorMessage = useCallback(
      (message) => {
        if (message === 'Refused') {
          showMessage(
            t('Your credit card is not valid, please try another one'),
            'danger'
          );
        } else {
          showMessage(message, 'danger');
        }
      },
      [showMessage, t]
    );

    const handleAdyenCheckoutPaymentComplete = useCallback(
      (response) => {
        if (response.resultCode === 'Authorised') {
          gtmAddPaymentInfo(paymentBrand.current);
          onSuccess({ paymentBrand: paymentBrand.current });
        } else {
          onFailure();
          showErrorMessage(response.resultCode);
        }
      },
      [onSuccess, onFailure, showErrorMessage]
    );

    const handleAdyenCheckoutAddtionalDetails = useCallback(
      async ({ data }) => {
        onSubmit3dsCode();
        try {
          const { data: responseData } = await axios.post(
            '/adyen-payments-details',
            {
              threeDSResult: data.details.threeDSResult,
              productCode,
            }
          );
          handleAdyenCheckoutPaymentComplete(responseData);
        } catch (e) {
          onFailure();
          showMessage(errorMessage);
        }
      },
      [
        onSubmit3dsCode,
        handleAdyenCheckoutPaymentComplete,
        onFailure,
        showMessage,
        productCode,
        errorMessage,
        axios,
      ]
    );

    const handleAdyenCheckoutBeforeSubmit = useCallback(
      (data, _element, { resolve }) => {
        paymentBrand.current = data.paymentMethod.brand;
        resolve(data);
      },
      []
    );

    const handleAdyenCheckoutActionHandled = useCallback(
      (action) => {
        if (action.actionDescription === 'challenge-iframe-loaded') {
          on3dsAuth();
        }
      },
      [on3dsAuth]
    );

    const createCheckout = useCallback(
      async (config, session) => {
        const checkoutComponent = await AdyenCheckout({
          ...config,
          clientKey:
            REACT_APP_BBE_CONFIG.adyen.specific[productCode]?.clientKey ||
            REACT_APP_BBE_CONFIG.adyen.generic.clientKey,
          showPayButton: false,
          showStoredPaymentMethods: env === 'dev',
          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              maskSecurityCode: true,
            },
            threeDS2: {
              challengeWindowSize: isMobile ? '01' : '04',
            },
            storedCard: {
              hideCVC: false,
            },
          },
          openFirstPaymentMethod,
          disableFinalAnimation: true,
          session,
          onPaymentCompleted: handleAdyenCheckoutPaymentComplete,
          onAdditionalDetails: handleAdyenCheckoutAddtionalDetails,
          beforeSubmit: handleAdyenCheckoutBeforeSubmit,
          onActionHandled: handleAdyenCheckoutActionHandled,
          onError: (error, _component) => {
            onFailure();
            showMessage(
              error?.message ||
                t('It seems a field is not valid, please review them.'),
              'danger'
            );
          },
        });

        if (paymentContainer.current) {
          checkoutComponent
            .create(adyenPaymentFormType)
            .mount(paymentContainer.current);
          checkout.current = checkoutComponent;
          await checkout.current.update();
        }

        onMountingEnd();
      },
      [
        productCode,
        isMobile,
        handleAdyenCheckoutPaymentComplete,
        handleAdyenCheckoutAddtionalDetails,
        handleAdyenCheckoutBeforeSubmit,
        handleAdyenCheckoutActionHandled,
        openFirstPaymentMethod,
        onMountingEnd,
        onFailure,
        showMessage,
        t,
      ]
    );

    useEffect(() => {
      initCreateSession();
    }, [bookings, accessToken, initCreateSession]);

    useEffect(() => {
      const { config, session } = payment;
      if (!session || !paymentContainer.current) {
        // createSession is not finished yet.
        return;
      }

      createCheckout(config, session);
    }, [createCheckout, payment]);

    return (
      <div>
        <div
          ref={paymentContainer}
          className={`payment${
            forceShowingPaymentMethodCheckBox
              ? ' force-showing-payment-method-checkbox'
              : ''
          }`}
          data-testid="paymentContainer"
          aria-label={`${paymentOption}-container`}
        />
        <div>
          <Message className="mt-4" />
        </div>
      </div>
    );
  }
);

Payment.propTypes = {
  onSessionCreated: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  metadata: PropTypes.object,
  onSubmit3dsCode: PropTypes.func,
  on3dsAuth: PropTypes.func,
  onCreateSessionFailure: PropTypes.func,
  productCode: PropTypes.string.isRequired,
  countryCode: PropTypes.string,
  payNowData: PropTypes.object,
  onMountingStart: PropTypes.func,
  onMountingEnd: PropTypes.func,
  paymentOption: PropTypes.string,
  openFirstPaymentMethod: PropTypes.bool,
  forceShowingPaymentMethodCheckBox: PropTypes.bool,
};

Payment.defaultProps = {
  metadata: null,
  onSessionCreated: () => {},
  onSuccess: () => {},
  onFailure: () => {},
};

export default Payment;
