import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Payment from './Payment';
import styles from './PaymentSwitcher.module.css';

const PaymentSwitcher = forwardRef(
  ({ onPaymentOptionChange, paymentProps }, ref) => {
    const payNowRef = useRef();
    const payAtHotelRef = useRef();
    const payNowEnabled = !!paymentProps.payNowData;
    const [paymentOption, setPaymentOption] = useState(
      payNowEnabled ? null : 'payAtHotel'
    );

    useImperativeHandle(ref, () => ({
      submit: () =>
        payNowEnabled && paymentOption === 'payNow'
          ? payNowRef.current.submit()
          : payAtHotelRef.current.submit(),
      reset: () => {
        if (payNowEnabled) {
          payNowRef.current.reset();
        }
        payAtHotelRef.current.reset();
      },
    }));

    const handlePaymentMethods = (paymentOption) => {
      if (!payNowEnabled) return;
      if (paymentOption === 'payNow') {
        payAtHotelRef.current.close();
        payNowRef.current.open();
      } else {
        payNowRef.current.close();
        payAtHotelRef.current.open();
      }

      setPaymentOption(paymentOption);
      onPaymentOptionChange(paymentOption);
    };

    return (
      <div>
        {payNowEnabled && (
          <div
            onClick={() =>
              paymentOption !== 'payNow' && handlePaymentMethods('payNow')
            }
            className={styles.PaymentSwitcher__paymentContainer}
          >
            <div className={styles.PaymentSwitcher__title}>PAY NOW</div>{' '}
            <div className={styles.PaymentSwitcher__infoText}>
              You will pay securely for your stay now.
            </div>
            <Payment
              ref={payNowRef}
              {...paymentProps}
              openFirstPaymentMethod={false}
              forceShowingPaymentMethodCheckBox={payNowEnabled}
              paymentOption="payNow"
            />
          </div>
        )}

        <div
          onClick={() =>
            paymentOption !== 'payAtHotel' && handlePaymentMethods('payAtHotel')
          }
          className={styles.PaymentSwitcher__paymentContainer}
        >
          <div className={styles.PaymentSwitcher__title}>PAY AT THE HOTEL</div>
          <div className={styles.PaymentSwitcher__infoText}>
            Your credit card will not be charged – we only need it to guarantee
            your booking. You will make the payment yourself when you check-in.
          </div>
          <Payment
            ref={payAtHotelRef}
            {...paymentProps}
            payNowData={null}
            openFirstPaymentMethod={!payNowEnabled}
            forceShowingPaymentMethodCheckBox={payNowEnabled}
            paymentOption="payAtHotel"
          />
        </div>
      </div>
    );
  }
);

PaymentSwitcher.propTypes = {
  onPaymentOptionChange: PropTypes.func.isRequired,
  paymentProps: PropTypes.object.isRequired,
};

export default PaymentSwitcher;
