import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: null,
  lastId: 0,
  list: [],
  agentProfile: null,
  agentProfileRatePlanAssociated: false,
  activeSpecialCode: '',
};

export const bookingStatuses = {
  UNSUBMITTED: 'UNSUBMITTED',
  ORDERED: 'ORDERED',
  CONFIRMED: 'CONFIRMED',
  CANCELLED: 'CANCELLED',
};

export const bookingsCartStatuses = {
  EDIT_BOOKING: 'EDIT_BOOKING',
  CHECK_OUT: 'CHECK_OUT',
  ADD_NEW_BOOKING: 'ADD_NEW_BOOKING',
  EDIT_ADDONS: 'EDIT_ADDONS',
  STAND_BY: 'STAND_BY',
};

export const bookingsSlice = createSlice({
  initialState,
  name: 'bookingsSlice',
  reducers: {
    resetBookingsState: () => initialState,
    addBooking: (state, action) => {
      if (!action.payload.status) {
        state.list.push({
          id: String(++state.lastId),
          status: bookingStatuses.UNSUBMITTED,
          ...action.payload,
        });
        state.current = state.lastId;
      } else {
        state.list.push(action.payload);
        state.current = action.payload.id;
      }
    },
    editCurrentBooking: (state, action) => {
      const bookingIndex = state.list.findIndex((b) => b.id === state.current);
      if (state.list[bookingIndex].agentId !== action.payload.agentId) {
        state.list = state.list.map((booking) => ({
          ...booking,
          agentId: action.payload.agentId,
        }));
      }
      state.list[bookingIndex] = {
        ...state.list[bookingIndex],
        ...action.payload,
      };
    },
    setCurrentBooking: (state, action) => {
      state.current = action.payload;
    },
    removeBooking: (state, action) => {
      state.list = state.list.filter(
        (booking) => booking.id !== action.payload
      );
      if (state.list.length === 0) {
        state.current = null;
      } else if (state.current === action.payload) {
        state.current = state.list[0].id;
      }
    },
    removeBookings: (state, action) => {
      state.list = state.list.filter(
        (booking) => !action.payload.includes(booking.id)
      );
      if (state.list.length === 0) {
        state.current = null;
      } else if (action.payload.includes(state.current)) {
        state.current = state.list[0].id;
      }
    },
    setAgentProfile: (state, action) => {
      state.agentProfile = action.payload;
    },
    removeAgentProfile: (state) => {
      state.agentProfile = null;
      state.list = state.list.filter(
        (booking) =>
          !booking.agentId && booking.status === bookingStatuses.UNSUBMITTED
      );
    },
    setAgentProfileRatePlanAssociated: (state, action) => {
      state.agentProfileRatePlanAssociated = action.payload;
    },
    setSpecialCodeValue: (state, action) => {
      state.activeSpecialCode = action.payload;
    },
  },
});

export const {
  addBooking,
  removeBooking,
  removeBookings,
  setAgentProfile,
  removeAgentProfile,
  resetBookingsState,
  setCurrentBooking,
  editCurrentBooking,
  setAgentProfileRatePlanAssociated,
  setSpecialCodeValue,
} = bookingsSlice.actions;

export default bookingsSlice.reducer;

export const fetchAgentProfile = (iata, axios) => async (dispatch) => {
  try {
    const { data } = await axios.get('/agent-profile', {
      params: { iata },
    });
    dispatch(setAgentProfile(data));
  } catch (e) {
    console.error(e);
  }
};
